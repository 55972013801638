import "./ClassCard.scss";
import { ReactComponent as PilatesIcon } from "@assets/icons/pilates-icon.svg";
import { ReactComponent as BarreIcon } from "@assets/icons/barre-icon.svg";
import { ReactComponent as SculptIcon } from "@assets/icons/sculpt-icon.svg";
import { IconName } from "@src/Interfaces/Class";
import { SetStateAction, useEffect } from "react";
import { format, isAfter, isValid, parseISO, sub } from "date-fns";
import { es } from "date-fns/locale"; // Import Spanish locale
import PowerIcon from "@assets/icons/power-pilates.jpg";
import { ScheduleType } from "@src/pages/users/pages/MyClasses/interfaces/Schedules";
import { formatCancelled } from "@src/utils/DatesFormat";

interface IClassCardProps {
	class: any;
	type: ScheduleType;
	setCancelClassModal?: React.Dispatch<SetStateAction<any>>;
	setLeaveWaitingListModal?: React.Dispatch<SetStateAction<any>>;
}

const icons: Record<IconName, JSX.Element> = {
	REFORMER: <PilatesIcon />,
	BARRE: <BarreIcon />,
	SCULPT: <SculptIcon />,
	"POWER PILATES": <img src={PowerIcon} alt="power pilates class" />,
};

function ClassCard(props: IClassCardProps) {
	const { setCancelClassModal, setLeaveWaitingListModal } = props;

	function getIcon(name: IconName) {
		return icons[name];
	}

	function formatDate(dateString: string) {
		const date = parseISO(dateString);
		if (isValid(date)) {
			return format(date, "EEEE, dd/MM/yyyy", { locale: es });
		}
		return "";
	}

	const startDate = parseISO(props.class.startDate);
	const now = new Date();
	const thirtyMinutesBeforeStart = sub(startDate, { minutes: 30 });

	const isClosed = isAfter(now, thirtyMinutesBeforeStart);

	return (
		<>
			{props.type === "wait_list" && (
				<div className="date-waiting-list-container">
					<h3>{formatDate(props.class.startDate)}</h3>
				</div>
			)}
			<div className="class-card-component">
				<div className="image-container">{getIcon(props.class.name)}</div>
				<div className="info-container">
					<h4>
						{props.class.startHour} - {props.class.endHour}
					</h4>
					<h2>{props.class.name}</h2>
					<h3>
						Sucursal: <b>{props.class.location}</b>
					</h3>
					<h3>
						Coach: <b>{props.class.fullname}</b>
					</h3>
					{props.type === "cancelled" && (
						<h3>
							Fecha de cancelación:{" "}
							<b>{props.class?.createdAt ? formatCancelled(props.class.createdAt) : ""}</b>
						</h3>
					)}
				</div>
				<div className="actions-container">
					{!isClosed && props.type === "future" && setCancelClassModal && (
						<button
							className="btn danger"
							onClick={() => setCancelClassModal({ show: true, clase: props.class })}
						>
							Cancelar
						</button>
					)}
					{!isClosed && props.type === "wait_list" && setLeaveWaitingListModal && (
						<button
							className="btn danger"
							onClick={() => {
								setLeaveWaitingListModal({ show: true, clase: props.class });
							}}
						>
							Abandonar
						</button>
					)}
				</div>
			</div>
		</>
	);
}

export default ClassCard;
