import { LocationI } from "@src/Interfaces/Locations";
import { fetchLocationsByLesson } from "@src/services/Lessons";
import { notifyError } from "@src/utils/NotificationFunctions";
import React, { ChangeEvent, useEffect, useState } from "react";

interface propsI {
	membership: any;
	handleLocationMembership: (locationId: string, membershipId: string) => void;
	locationId: string;
}

const MembershipLocationSelector = ({ membership, handleLocationMembership, locationId }: propsI) => {
	const [locations, setLocations] = useState<Array<LocationI>>([]);
	const [loadingLocations, setLoadingLocations] = useState<boolean>(true);

	const getLocationsByLesson = async () => {
		try {
			const res = await fetchLocationsByLesson(membership.lesson.id);
			setLocations(res);
		} catch (error) {
			console.error(error);
			notifyError(
				"Hubo un error al cargar las sucursales.\nFavor probar tu conexión a internet o recargar la página."
			);
		} finally {
			setLoadingLocations(false);
		}
	};

	const handleChange = (e: ChangeEvent<HTMLSelectElement>) => {
		e.preventDefault();
		handleLocationMembership(e.target.value, membership.id);
	};

	useEffect(() => {
		getLocationsByLesson();
	}, []);

	if (loadingLocations)
		return (
			<div className="d-flex justify-content-center align-items-center mb-3">
				<div className="spinner-border text-primary" role="status">
					<span className="visually-hidden">Loading...</span>
				</div>
			</div>
		);

	if (locations.length === 0) return <h2>No se encontraron sucursales para esta membresía</h2>;

	return (
		<div className="border rounded p-2">
			<h3 className="fs-16 fw-bold mb-3">
				{membership.lesson.name} - {membership.classPayed} {membership.plan.name}
			</h3>
			<select
				className="form-select fs-18"
				value={locationId}
				aria-label="Selecciona una sucursal"
				onChange={handleChange}
			>
				<option value={""} disabled>
					Selecciona una sucursal
				</option>
				{locations.map((location) => (
					<option value={location.id} key={`membership-${membership.id}-location-${location.id}`}>
						{location.name}
					</option>
				))}
			</select>
		</div>
	);
};

export default MembershipLocationSelector;
