import { useEffect, useState } from "react";
import "./ScheduleClassModal.scss";
import { Modal } from "react-bootstrap";
import { useClassesContext } from "@src/context/ClassesContext";
import { useUserContext } from "@src/context/UserContext";
import { addClass } from "@src/services/ClassesService";
import { notifyError, notifySuccess } from "@src/utils/NotificationFunctions";
import { useNavigate } from "react-router-dom";

interface ScheduleClassModalPropsI {
  show: boolean;
  onHide: () => void;
  clase: any | null;
}

function ScheduleClassModal(props: ScheduleClassModalPropsI) {
  const { show, onHide, clase } = props;
  const { user, userToken, getUserInfo } = useUserContext();
  const [loadingAdd, setLoadingAdd] = useState<boolean>(false);
  const navigate = useNavigate();

  const addSchedule = async () => {
    setLoadingAdd(true);
    return addClass({ classId: clase.id, userId: user.id }, userToken).then(
      (res) => {
        if (res.status === 201) {
          getUserInfo();
          notifySuccess("Clase agendada exitosamente.");
          navigate("/mis-clases");
        } else {
          notifyError(res.response.data.message);
          setLoadingAdd(false);
        }
      }
    );
  };

  return (
    <Modal
      show={show}
      onHide={onHide}
      aria-labelledby="contained-modal-title-vcenter"
      centered
      backdrop={"static"}
    >
      <Modal.Header closeButton>
        <Modal.Title id="contained-modal-title-vcenter">
          <h2>Aviso</h2>
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <h3>¿Estas seguro que deseas agendar esta clase?</h3>
      </Modal.Body>
      <Modal.Footer>
        <button className="btn secondary" onClick={props.onHide}>
          No
        </button>
        <button
          className="btn success"
          onClick={addSchedule}
          disabled={!clase || loadingAdd}
        >
          Sí
        </button>
      </Modal.Footer>
    </Modal>
  );
}

export default ScheduleClassModal;
