import React from "react";
import { ScheduleType } from "../../interfaces/Schedules";
import "./SchedulesHistorySelector.scss";

type SchedulesHistorySelectorProps = {
	selected: ScheduleType;
	handleSelected: (newHistory: ScheduleType) => void;
};

const SchedulesHistorySelector = ({ selected, handleSelected }: SchedulesHistorySelectorProps) => {
	return (
		<ul className="schedules-history-selector">
			<li
				role="button"
				className={`${selected === "future" ? "active" : ""}`}
				onClick={() => handleSelected("future")}
			>
				Próximas
			</li>
			<li
				role="button"
				className={`${selected === "past" ? "active" : ""}`}
				onClick={() => handleSelected("past")}
			>
				Pasadas
			</li>
			<li
				role="button"
				className={`${selected === "cancelled" ? "active" : ""}`}
				onClick={() => handleSelected("cancelled")}
			>
				Canceladas
			</li>
			<li
				role="button"
				className={`${selected === "wait_list" ? "active" : ""}`}
				onClick={() => handleSelected("wait_list")}
			>
				Lista de espera
			</li>
		</ul>
	);
};

export default SchedulesHistorySelector;
