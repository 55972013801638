import { LocationI } from "@src/Interfaces/Locations";
import { fetchLocationsByLesson } from "@src/services/Lessons";
import { notifyError } from "@src/utils/NotificationFunctions";
import React, { ChangeEvent, FormEvent, useEffect, useState } from "react";
import CustomSpinner from "../CustomSpinner/CustomSpinner";

interface LocationByLessonProps {
	locationId: string;
	handleChange: (newLocation: LocationI) => void;
	lessonId: string;
	id: string;
}

const LocationByLessonSelect = ({ locationId, handleChange, lessonId, id }: LocationByLessonProps) => {
	const [locations, setLocations] = useState<LocationI[]>([]);
	const [loadingLocations, setLoadingLocations] = useState<boolean>(true);

	const getLocationsByLesson = async () => {
		try {
			const res = await fetchLocationsByLesson(lessonId);
			setLocations(res);
		} catch (error) {
			console.error(error);
			notifyError(
				"Hubo un error al cargar las sucursales.\nFavor probar tu conexión a internet o recargar la página."
			);
		} finally {
			setLoadingLocations(false);
		}
	};

	const handleLocationSelection = (e: ChangeEvent<HTMLSelectElement>) => {
		const locationId = e.target.value;
		const selectedLocation = locations.filter((location) => location.id === locationId)[0];

		handleChange(selectedLocation);
	};

	useEffect(() => {
		getLocationsByLesson();
	}, []);

	if (loadingLocations)
		return (
			<div className="d-flex justify-content-center">
				<CustomSpinner size={3} borderWidth={4} color="text-primary" />
			</div>
		);

	if (locations.length === 0) return <h2>No se encontraron sucursales para esta membresía</h2>;

	return (
		<select
			className="form-select fs-18"
			value={locationId}
			aria-label="Selecciona una sucursal"
			onChange={handleLocationSelection}
			id={id}
		>
			<option value={""} disabled>
				Selecciona una sucursal
			</option>
			{locations.map((location) => (
				<option value={location.id} key={`membership-${id}-location-${location.id}`}>
					{location.name}
				</option>
			))}
		</select>
	);
};

export default LocationByLessonSelect;
