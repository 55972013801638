import axiosInstance from "./AxiosInstance";
type SchedulesFilterT = "cancelled" | "past" | "future" | "wait_list";

export async function fetchMemberships(userToken: string) {
	return axiosInstance
		.get(`/membership/getMembershipsByUser`)
		.then((res) => {
			return res;
		})
		.catch((error) => {
			return error;
		});
}

export async function getScheduledClassesUser(userToken: string, userId: string) {
	return axiosInstance
		.get(`/schedule/next/${userId}`)
		.then((res) => {
			return res;
		})
		.catch((error) => {
			return error;
		});
}

export async function getSchedulesByMembershipAndFilter(
	membershipId: string,
	userId: string,
	scheduleFilter: SchedulesFilterT
) {
	try {
		const response = await axiosInstance.get(`/schedule/${membershipId}/${scheduleFilter}/${userId}`);
		return response.data;
	} catch (error) {
		throw error;
	}
}
export async function getWaitListsByMembership(membershipId: string, userId: string) {
	try {
		const response = await axiosInstance.get(`/users/waiting_list/${membershipId}/${userId}`);
		return response.data;
	} catch (error) {
		throw error;
	}
}

export async function getUserWaitingLists(userToken: string, userId: string) {
	return axiosInstance
		.get(`/users/waiting_list/active`, {
			params: {
				user: {
					id: userId,
				},
			},
		})
		.then((res) => {
			return res;
		})
		.catch((error) => {
			return error.response;
		});
}

export async function changePassword(
	userToken: string,
	passwords: { password: string; new_password: string },
	userId: string
) {
	return axiosInstance
		.post(`/users/change_password`, { ...passwords, userId })
		.then((res: any) => {
			return res;
		})
		.catch((error) => {
			return error.response;
		});
}

export async function updateUser(userToken: string, userId: string, user: any) {
	return axiosInstance
		.put(`/users/${userId}`, {
			email: user.email,
			mobile_no: user.mobile_no,
			name: user.name,
			lastname: user.lastname,
			address1: user.address1,
			profile_image: user.profile_image,
			roles: user.roles,
			metadata: user.metadata,
			...(user.password !== "" && { password: user.password }),
		})
		.then((res) => {
			return res;
		})
		.catch((error) => {
			return error;
		});
}

export async function fetchOpenInvoiceByMembership(token: string, membershipId: string, userId: string) {
	return axiosInstance
		.get(`/invoices/client/${userId}/${membershipId}`)
		.then((res) => {
			return res;
		})
		.catch((error) => {
			throw error;
		});
}

export async function getNotifications(token: string, userId: string) {
	return axiosInstance
		.get(`/notifications/${userId}/notifications`)
		.then((res) => {
			return res;
		})
		.catch((error) => {
			throw error;
		});
}

export const verifyAttendanceByUser = async (userId: string, scheduleId: string) => {
	return axiosInstance
		.get(`/schedule/alumn/check-class-attendance-by-qr?userId=${userId}&scheduleId=${scheduleId}`)
		.then((res) => {
			return res.data;
		})
		.catch((error) => {
			throw error;
		});
};
