import "./SpinnerCustom.scss";

function SpinnerCustom() {
  return (
    <div className="spinner-custom-main-container">
      <div
        className="spinner-border text-primary"
        role="status"
      >
        <span className="sr-only">Loading...</span>
      </div>
    </div>
  );
}

export default SpinnerCustom;
