import CustomSpinner from "@src/components/CustomSpinner/CustomSpinner";
import React from "react";

interface SummaryPropsI {
	getTotal: () => string;
	step: number;
	loadingSubmit: boolean;
  completeOrder: () => void;
  plansSelected: number;
}

const Summary = ({ getTotal, step, loadingSubmit, completeOrder, plansSelected }: SummaryPropsI) => {
	return (
		<div className="summary-container">
			<div className="summary-table">
				<div className="top">
					<h3>Resumen de compra</h3>
				</div>
				<div className="summary">
					<h3>Detalles de precio</h3>
					<div className="sub-total d-flex justify-content-between mt-4">
						<p>SubTotal:</p>
						<p>${getTotal()} MXN</p>
					</div>
				</div>
				<div className="total d-flex justify-content-between">
					<h3>Total:</h3>
					<h3>${getTotal()} MXN</h3>
				</div>
			</div>
			{step === 1 && (
				<button
					className="btn primary mt-4 w-100"
					type="button"
					onClick={completeOrder}
				>
					Completar orden
				</button>
			)}
			{step === 2 && (
				<button className="btn primary mt-4 w-100" type="submit" disabled={loadingSubmit}>
					{!loadingSubmit ? "Realizar pago" : <CustomSpinner size={1.6} borderWidth={2} />}
				</button>
			)}
		</div>
	);
};

export default Summary;
