import React, { useEffect } from "react";
import ReactDOM from "react-dom/client";
import "./styles/index.scss";
import reportWebVitals from "./reportWebVitals";
import { Outlet, RouterProvider, createBrowserRouter, useNavigate } from "react-router-dom";
import MainLayout from "./layouts/MainLayout";
import UserHome from "./pages/users/UserHome";
import ErrorRoute from "./components/ErrorRoute/ErrorRoute";
import { UserContextProvider, useUserContext } from "./context/UserContext";
import Login from "./pages/login/Login";
import MyClasses from "./pages/users/pages/MyClasses/MyClasses";
import WaitingList from "./pages/users/pages/WaitingList/WaitingList";
import AvailableClasses from "./pages/users/pages/AvailableClasses/AvailableClasses";
import Profile from "./pages/users/pages/Profile/Profile";
import { ClassesContextProvider } from "./context/ClassesContext";
import { Toaster } from "react-hot-toast";
import "react-date-picker/dist/DatePicker.css";
import "react-calendar/dist/Calendar.css";
import ResetPassword from "./pages/resetPassword/ResetPassword";
import Purchase from "./pages/users/pages/Purchase/Purchase";
import ScanQr from "./pages/users/pages/ScanQr/ScanQr";
import MembershipsContextProvider from "./context/MembershipsContext";
import MyClassesContextProvider from "./context/MyClassesContext";

const root = ReactDOM.createRoot(document.getElementById("root") as HTMLElement);

function AuthWrapper() {
	const { user } = useUserContext();
	const navigate = useNavigate();

	useEffect(() => {
		if (!user) {
			navigate("/login");
		}
	}, [user, navigate]);

	if (!user) return <></>;

	return <Outlet />;
}

function PublicRoute() {
	const { user } = useUserContext();
	const navigate = useNavigate();

	useEffect(() => {
		if (user) {
			navigate("/");
		}
	}, [user, navigate]);

	if (user) return <></>;
	return <Outlet />;
}

const router = createBrowserRouter([
	{
		path: "/",
		element: (
			<>
				<Toaster />
				<UserContextProvider>
					<MembershipsContextProvider>
						<MainLayout />{" "}
					</MembershipsContextProvider>
				</UserContextProvider>
			</>
		),
		children: [
			{
				path: "",
				element: <AuthWrapper />,
				children: [
					{
						path: "",
						element: <UserHome />,
						children: [
							{
								path: "",
								element: <Profile />,
							},
							{
								path: "mis-clases",
								element: (
									<MyClassesContextProvider>
										<MyClasses />
									</MyClassesContextProvider>
								),
							},
							{
								path: "clases-disponibles",
								element: <ClassesContextProvider />,
								children: [{ path: "", element: <AvailableClasses /> }],
							},
							{
								path: "escanear",
								element: <ScanQr />,
							},
							{
								path: "comprar",
								element: <Purchase />,
							},
						],
					},
					{
						path: "cambiar-contraseña",
						element: <ResetPassword />,
					},
				],
			},
			{
				path: "login",
				element: <PublicRoute />,
				children: [
					{
						path: "",
						element: <Login />,
					},
				],
			},
			{
				path: "*",
				element: <ErrorRoute />,
			},
		],
	},
]);

root.render(
	<React.StrictMode>
		<RouterProvider router={router} />
	</React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
