import { useMembershipContext } from "@src/context/MembershipsContext";
import React, { ChangeEvent, useEffect, useState } from "react";
import { Modal } from "react-bootstrap";
import MembershipLocationSelector from "./components/MembershipLocationSelector";
import { notifyError, notifySuccess } from "@src/utils/NotificationFunctions";
import CustomSpinner from "../CustomSpinner/CustomSpinner";
import { MembershipLocationI } from "@src/Interfaces/Memberships";
import { updateMermbershipsLocations } from "@src/services/Memberships";

interface propsI {
	show: boolean;
	onHide: () => void;
}

const MembershipLocationMissingModal = ({ show, onHide }: propsI) => {
	const { missingLocations, getMemberships } = useMembershipContext();
	const [membershipsLocations, setMembershipsLocations] = useState<MembershipLocationI[]>(
		missingLocations.map((membership) => ({ locationId: "", membershipId: membership.id }))
	);
	const [loadingSave, setLoadingSave] = useState<boolean>(false);

	const handleLocationMembership = (locationId: string, membershipId: string) => {
		const tempArray = [...membershipsLocations];
		const findIndex = tempArray.findIndex((e) => e.membershipId === membershipId);
		if (findIndex < 0) {
			tempArray.push({ locationId, membershipId });
		} else {
			tempArray[findIndex].locationId = locationId;
		}

		setMembershipsLocations(tempArray);
	};

	const handleSave = async () => {
		try {
			setLoadingSave(true);
			const allLocationsNotEmpty = membershipsLocations.every((item) => item.locationId);
			if (allLocationsNotEmpty) {
				const response = await updateMermbershipsLocations(membershipsLocations);

				notifySuccess("Membresias actualizadas correctamente");
				getMemberships();
			} else {
				notifyError("Es necesario que selecciones una sucursal para todas tus membresias");
			}
		} catch (error) {
			let errorMessage = "Ha ocurrido un error inesperado";
			if (error instanceof Error) {
				errorMessage = error.message;
			}
			notifyError(errorMessage);
		} finally {
			setLoadingSave(false);
		}
	};

	return (
		<Modal
			show={show}
			onHide={onHide}
			size="lg"
			aria-labelledby="contained-modal-title-vcenter"
			centered
			backdrop="static"
		>
			<Modal.Header closeButton>
				<Modal.Title id="contained-modal-title-vcenter" className="fs-24">
					Indica la sucursal de tu interés.
				</Modal.Title>
			</Modal.Header>
			<Modal.Body>
				<h3 className="fs-20">Es necesario seleccionar una sucursal por cada membresía:</h3>

				<div className="my-2 d-flex flex-column gap-3">
					{missingLocations.map((membership) => {
						return (
							<MembershipLocationSelector
								membership={membership}
								locationId={
									membershipsLocations.filter((e) => e.membershipId === membership.id)[0].locationId
								}
								handleLocationMembership={handleLocationMembership}
								key={`location-selector-${membership.id}`}
							/>
						);
					})}
				</div>
				<p className="fs-16 fw-bolder">
					*Si deseas asistir a otra sucursal, deberás adquirir una nueva membresía o el Paquete ALL ACCESS
				</p>
			</Modal.Body>
			<Modal.Footer>
				<button
					className="btn btn-primary p-1 d-flex justify-content-center align-items-center"
					onClick={handleSave}
					disabled={loadingSave}
				>
					{loadingSave ? <CustomSpinner size={2} borderWidth={3} /> : "Continuar"}
				</button>
			</Modal.Footer>
		</Modal>
	);
};

export default MembershipLocationMissingModal;
