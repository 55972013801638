import axios from "axios";
import axiosInstance from "./AxiosInstance";

export async function fetchLessons() {
	return await axiosInstance
		.get(`/lesson/prices`)
		.then((res) => {
			return res;
		})
		.catch((error) => {
			return error;
		});
}

export async function newMembership(membershipData: any, userToken: string) {
	return await axiosInstance
		.post(`/users/buy_membership`, membershipData)
		.then((res) => {
			return res.data;
		})
		.catch((error) => {
			return error;
		});
}

export async function fetchLocationsByLesson(lessonId: string) {
	return axiosInstance
		.get(`location/lesson/${lessonId}`)
		.then((res) => {
			return res.data;
		})
		.catch((error) => {
			throw error;
		});
}

export async function fetchLocationById(locationId: string) {
	try {
		const res = await axiosInstance.get(`location/${locationId}`);
		return res.data;
	} catch (error) {
		throw error;
	}
}
