import Header from "@src/components/Header/Header";
import "./UserHome.scss";
import UserNavbar from "@src/components/UserNavbar/UserNavbar";
import { Outlet } from "react-router-dom";

function UserHome() {
  return (
    <div className="user-home-main-container">
      <Header />
      <UserNavbar />
      <Outlet />
    </div>
  );
}

export default UserHome;
