import { ReactNode } from "react";
import "./BaseModal.scss";
import { Modal } from "react-bootstrap";

interface BaseModalPropsI {
	children: ReactNode;
	title: string;
	show: boolean;
	onHide: () => void;
}

function BaseModal({ children, title, show, onHide }: BaseModalPropsI) {
	return (
		<Modal show={show} onHide={onHide} size="lg" aria-labelledby="contained-modal-title-vcenter" centered>
			<Modal.Header closeButton>
				<Modal.Title id="contained-modal-title-vcenter" className="fs-2">{title}</Modal.Title>
			</Modal.Header>
			<Modal.Body>{children}</Modal.Body>
		</Modal>
	);
}

export default BaseModal;
