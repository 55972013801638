import { useEffect, useState } from "react";
import "./CancelClassModal.scss";
import { Modal } from "react-bootstrap";
import { cancelClass } from "@src/services/ClassesService";
import { useUserContext } from "@src/context/UserContext";
import { notifyError, notifySuccess } from "@src/utils/NotificationFunctions";

interface CancelClassModalPropsI {
	show: boolean;
	onHide: () => void;
	clase: any | null;
	getClasses: () => void;
}

function CancelClassModal(props: CancelClassModalPropsI) {
	const { show, onHide, clase, getClasses } = props;
	const { user, userToken } = useUserContext();
	const [loadingCancel, setLoadingCancel] = useState<boolean>(false);

	const cancelSchedule = async () => {
		setLoadingCancel(true);
		cancelClass({ classId: props.clase.id, userId: user.id }, userToken).then((res) => {
			if (res.status === 201) {
				notifySuccess("Clase cancelada exitosamente");
				getClasses();
				props.onHide();
				setLoadingCancel(false);
			} else {
				notifyError(res.response.data.message);
				setLoadingCancel(false);
			}
		});
	};

	return (
		<Modal
			show={show}
			onHide={onHide}
			aria-labelledby="contained-modal-title-vcenter"
			centered
			backdrop={"static"}
		>
			<Modal.Header closeButton>
				<Modal.Title id="contained-modal-title-vcenter">
					<h2>Aviso</h2>
				</Modal.Title>
			</Modal.Header>
			<Modal.Body>
				<h3>¿Estas seguro que deseas cancelar esta clase?</h3>
			</Modal.Body>
			<Modal.Footer>
				<button className="btn secondary" onClick={props.onHide}>
					No
				</button>
				<button className="btn danger" onClick={cancelSchedule} disabled={!clase || loadingCancel}>
					Sí
				</button>
			</Modal.Footer>
		</Modal>
	);
}

export default CancelClassModal;
