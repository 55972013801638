import QRScanner from "@src/components/QRScanner/QRScanner";
import "./ScanQr.scss";
import { notifyError, notifySuccess } from "@src/utils/NotificationFunctions";
import { useUserContext } from "@src/context/UserContext";
import { verifyAttendanceByUser } from "@src/services/UserService";

const ScanQr = () => {
	const { user } = useUserContext();
	const attendance = async (scheduleId: string) => {
		await verifyAttendanceByUser(user.id, scheduleId)
			.then((res) => {
				notifySuccess(res.message);
			})
			.catch((error) => {
				notifyError(error.response.data.message);
			});
	};

	return (
		<div className="scann d-flex flex-column align-items-center gap-4 pt-4">
			<h2 className="fw-bold">Escanea tu código QR para confirmar tu asistencia a la clase</h2>
			<QRScanner onScan={attendance} mainClassName="gap-2" />
		</div>
	);
};

export default ScanQr;
