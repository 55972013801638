import {
  ReactNode,
  createContext,
  useContext,
  useEffect,
  useState,
} from "react";
import { UserContext, useUserContext } from "./UserContext";
import { Outlet } from "react-router-dom";
import { addClass, getNextClasses } from "@src/services/ClassesService";

interface ClassesContextProviderPropsI {}

export const ClassesContext = createContext<any>({
  nextClasses: [],
  loading: true,
  getClasses: () => {},
});

export function useClassesContext() {
  return useContext(ClassesContext);
}

export const ClassesContextProvider = (props: ClassesContextProviderPropsI) => {
  const [nextClasses, setNextClasses] = useState<Array<any>>([]);
  const [loading, setLoading] = useState<boolean>(true);
  const { user } = useUserContext();

  const getClasses = async () => {
    setLoading(true);
    getNextClasses(user.id)
      .then((res) => {
        setNextClasses(res);
      })
      .catch((error) => {
        throw error;
      })
      .finally(() => {
        setLoading(false);
      });
  };

  useEffect(() => {
    getClasses();
  }, []);

  const values = { nextClasses, loading, getClasses };

  return (
    <ClassesContext.Provider value={values}>
      <Outlet />
    </ClassesContext.Provider>
  );
};
