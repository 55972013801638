import { useUserContext } from "@src/context/UserContext";
import React, {
	Dispatch,
	ReactNode,
	SetStateAction,
	createContext,
	useCallback,
	useContext,
	useEffect,
	useState,
} from "react";
import { ScheduleType } from "../pages/users/pages/MyClasses/interfaces/Schedules";
import { getSchedulesByMembershipAndFilter, getWaitListsByMembership } from "@src/services/UserService";
import { notifyError } from "@src/utils/NotificationFunctions";
import CancelClassModal from "@src/components/CancelClassModal/CancelClassModal";
import LeaveWaitingListModal from "@src/components/LeaveWaitingListModal/LeaveWaitingListModal";

type CancelScheduleT = {
	show: boolean;
	clase: any | null;
};

interface MyClassesContextI {
	classes: Array<any>;
	selectedHistory: ScheduleType;
	selectedMembership: string;
	loadingClasses: boolean;
	setCancelClassModal: Dispatch<SetStateAction<CancelScheduleT>>;
	setLeaveWaitingListModal: Dispatch<SetStateAction<CancelScheduleT>>;
	handleSelectedHistory: (newValue: ScheduleType) => void;
	handleSelectedMembership: (membershipId: string) => void;
}

const MyClassesContext = createContext<MyClassesContextI>({
	classes: [],
	selectedHistory: "future",
	selectedMembership: "",
	loadingClasses: true,
	setCancelClassModal: () => {},
	setLeaveWaitingListModal: () => {},
	handleSelectedHistory: () => {},
	handleSelectedMembership: () => {},
});

export const useMyClassesContext = () => {
	return useContext(MyClassesContext);
};

const MyClassesContextProvider = ({ children }: { children: ReactNode }) => {
	const { user } = useUserContext();
	const [cancelClassModal, setCancelClassModal] = useState({
		show: false,
		clase: null,
	});
	const [selectedMembership, setSelectedMembership] = useState("");
	const [classes, setClasses] = useState<Array<any>>([]);
	const [selectedHistory, setselectedHistory] = useState<ScheduleType>("future");
	const [leaveWaitingListModal, setLeaveWaitingListModal] = useState({
		show: false,
		clase: null,
	});
	const [loadingClasses, setLoadingClasses] = useState(true);

	const getClasses = useCallback(async () => {
		try {
			const res = await (selectedHistory === "wait_list"
				? getWaitListsByMembership(selectedMembership, user.id)
				: getSchedulesByMembershipAndFilter(selectedMembership, user.id, selectedHistory));

			setClasses(selectedHistory === "wait_list" ? res : res.dates || []);
		} catch (error) {
			console.error(error);
			notifyError(
				"Hubo un error al tratar de obtener tus clases.\n\nPor favor verifica tu conexión a internet e intentalo de nuevo."
			);
		} finally {
			setLoadingClasses(false);
		}
	}, [selectedHistory, selectedMembership, user.id]);

	const handleSelectedHistory = (newSelectedHistory: ScheduleType) => {
		if (newSelectedHistory !== selectedHistory) {
			setLoadingClasses(true);
			setselectedHistory(newSelectedHistory);
		}
	};

	const handleSelectedMembership = (membershipId: string) => {
		setSelectedMembership(membershipId);
	};

	useEffect(() => {
		if (selectedMembership && selectedHistory) {
			getClasses();
		}
	}, [getClasses, selectedHistory, selectedMembership]);

	return (
		<MyClassesContext.Provider
			value={{
				classes,
				selectedHistory,
				selectedMembership,
				loadingClasses,
				setCancelClassModal,
				setLeaveWaitingListModal,
				handleSelectedHistory,
				handleSelectedMembership,
			}}
		>
			<CancelClassModal
				show={cancelClassModal.show}
				onHide={() => setCancelClassModal({ show: false, clase: null })}
				clase={cancelClassModal.clase}
				getClasses={getClasses}
			/>
			<LeaveWaitingListModal
				show={leaveWaitingListModal.show}
				onHide={() => setLeaveWaitingListModal({ show: false, clase: null })}
				clase={leaveWaitingListModal.clase}
				getClasses={getClasses}
			/>
			{children}
		</MyClassesContext.Provider>
	);
};

export default MyClassesContextProvider;
