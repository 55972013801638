import axiosInstance from "./AxiosInstance";

export async function getNextClasses(userId: string) {
	return axiosInstance
		.get(`/schedule/available/${userId}`)
		.then((res) => {
			return res.data;
		})
		.catch((error) => {
			console.error(error);
			return error;
		});
}

export async function addClass(data: any, userToken: string) {
	return axiosInstance
		.post(`/schedule/add_user`, {
			scheduleId: data.classId,
			userId: data.userId,
			justThisOne: true,
		})
		.then((res) => {
			return res;
		})
		.catch((error) => {
			return error;
		});
}

export async function addWaitingList(data: any, userToken: string) {
	return axiosInstance
		.post(`/waiting-list/add_user`, {
			waitingListId: data.waitingListId,
			userId: data.userId,
		})
		.then((res) => {
			return res;
		})
		.catch((error) => {
			return error;
		});
}

export async function cancelClass(data: any, userToken: string) {
	return axiosInstance
		.post(`/schedule/cancel_user`, {
			scheduleId: data.classId,
			userId: data.userId,
			justThisOne: true,
		})
		.then((res) => {
			return res;
		})
		.catch((error) => {
			return error;
		});
}

export async function cancelWaitingList(data: any, userToken: string) {
	return axiosInstance
		.delete(`/waiting-list/remove_user/?userId=${data.userId}&waitingListId=${data.waitingListId}`)
		.then((res) => {
			return res;
		})
		.catch((error) => {
			return error;
		});
}
