import { ReactComponent as PilatesIcon } from "@assets/icons/pilates-icon.svg";
import { ReactComponent as BarreIcon } from "@assets/icons/barre-icon.svg";
import { ReactComponent as SculptIcon } from "@assets/icons/sculpt-icon.svg";
import { LessonPricingI, PlanI } from "@src/Interfaces/Lesson";
import { ChangeEvent, useEffect, useState } from "react";
import { returnLessonImage } from "@src/utils/CommonFunctions";
import LocationByLessonSelect from "@src/components/LocationByLessonSelect/LocationByLessonSelect";
import { LocationI } from "@src/Interfaces/Locations";

interface PropsI {
	plan: PlanI;
	handlePlan: (plan: PlanI) => void;
	handlePricing: (plan: PlanI, index: number) => void;
	handleLocationByPlan: (newLocation: LocationI, planId: string) => void;
}
function ItemCard({ plan, handlePlan, handlePricing, handleLocationByPlan }: PropsI) {
	const [pricing, setPricing] = useState<LessonPricingI>(plan.lessons.pricing[plan.pricingIndex]);

	const handleSelectChange = (newLocation: LocationI) => {
		handleLocationByPlan(newLocation, plan.id);
	};

	useEffect(() => {
		setPricing(plan.lessons.pricing[plan.pricingIndex]);
	}, [plan.pricingIndex]);

	return (
		<div className="item-card">
			<button className="btn close" onClick={() => handlePlan(plan)} type="button">
				x
			</button>
			<div className="image-container">{returnLessonImage(plan.name)}</div>
			<div className="class-info">
				<h3>{plan.name}</h3>
				<div className="quantity-container">
					<h3>Reservas sueltas</h3>
					<div className="quantities-container">
						{plan.lessons.pricing.map((pack, i) => {
							return (
								<div
									className={`pack-container ${pricing.id === pack.id ? "active primary-bg" : ""}`}
									onClick={() => handlePricing(plan, i)}
									key={pack.id}
								>
									<h4>
										{pack.reservations === 30
											? "Ilimitado"
											: pack.reservations + (pack.reservations > 1 ? " reservas" : " reserva")}
									</h4>
									<h5>${pack.cost}</h5>
								</div>
							);
						})}
					</div>
					<div className="mt-2">
						<h3 className="mb-1 fw-bold">Indica la sucursal de tu interés.</h3>
						<LocationByLessonSelect
							locationId={plan.locationId}
							handleChange={handleSelectChange}
							id={plan.lessons.id}
							lessonId={plan.lessons.id}
						/>
						<p className="fs-14 fw-bolder mt-3">
							*Si deseas asistir a otra sucursal, deberás adquirir una nueva membresía o el Paquete ALL ACCESS
						</p>
					</div>
				</div>
			</div>
		</div>
	);
}

export default ItemCard;
