import axiosInstance from "./AxiosInstance";

export async function loginAuth(email: string, password: string) {
	return axiosInstance
		.post(`/sign-in`, { email, password })
		.then((res) => {
			return res;
		})
		.catch((error) => {
			return error;
		});
}

export async function getActualUserInfo(userId: string, userToken: string) {
	return axiosInstance
		.get(`/users/${userId}`)
		.then((res) => {
			return res;
		})
		.catch((error) => {
			return error;
		});
}
