import "./MyClasses.scss";
import EmptyContainer from "@src/components/EmptyContainer/EmptyContainer";
import ClassCard from "@src/components/ClassCard/ClassCard";
import { format, isValid, parse } from "date-fns";
import { enUS, es } from "date-fns/locale";
import { useMembershipContext } from "@src/context/MembershipsContext";
import MembershipSelector from "./components/MembershipSelector/MembershipSelector";
import SchedulesHistorySelector from "./components/SchedulesHistorySelector/SchedulesHistorySelector";
import SpinnerCustom from "@src/components/SpinnerCustom/SpinerCustom";
import { useMyClassesContext } from "@src/context/MyClassesContext";

function MyClasses() {
	const { memberships } = useMembershipContext();
	const {
		classes,
		selectedHistory,
		selectedMembership,
		loadingClasses,
		setLeaveWaitingListModal,
		setCancelClassModal,
		handleSelectedHistory,
		handleSelectedMembership,
	} = useMyClassesContext();

	function returnCurrentDay(currentDay: string) {
		try {
			const date = parse(currentDay, "d MMMM yyyy", new Date(), { locale: enUS });
			if (!isValid(date)) {
				console.error(`Invalid date parsed: ${currentDay}`);
				return "";
			}
			const formattedDate = format(date, "EEEE, d 'de' MMMM yyyy", {
				locale: es,
			});
			return formattedDate;
		} catch (error) {
			console.error(`Error parsing date: ${currentDay}`, error);
			return "";
		}
	}

	return (
		<div className="my-classes-main-container">
			<div className="text-start w-100 p-2">
				<h3 className="fs-20">Membresías</h3>
			</div>
			<div className="p-2 w-100">
				<MembershipSelector
					memberships={memberships}
					handleSelect={handleSelectedMembership}
					selectedMembershipId={selectedMembership}
				/>
			</div>
			{selectedMembership && (
				<SchedulesHistorySelector selected={selectedHistory} handleSelected={handleSelectedHistory} />
			)}
			<hr className="w-100" />
			{!selectedMembership ? (
				<EmptyContainer message="Selecciona una membresía para ver tus reservas" showIcon={false} />
			) : loadingClasses ? (
				<SpinnerCustom />
			) : (
				<>
					{classes.length === 0 && selectedMembership && (
						<EmptyContainer message="No se encontraron clases en la selección actual" />
					)}
					{classes.length > 0 &&
						selectedHistory !== "wait_list" &&
						classes.map((currentDay: any, i: number) => {
							return (
								<div className="day-container" key={currentDay.id}>
									<div className="header-day-container">
										<h2>{currentDay.id ? returnCurrentDay(currentDay.id) : ""}</h2>
									</div>
									<div className="classes-cards-container">
										{currentDay.data?.map((sClass: any, j: number) => {
											return (
												<ClassCard
													class={sClass}
													type={selectedHistory}
													setCancelClassModal={setCancelClassModal}
													key={`class-card-${j}`}
												/>
											);
										})}
									</div>
								</div>
							);
						})}
					{classes.length > 0 &&
						selectedHistory === "wait_list" &&
						classes.map((clase: any, i: number) => {
							return (
								<ClassCard
									class={clase}
									type={selectedHistory}
									setLeaveWaitingListModal={setLeaveWaitingListModal}
									key={`class-card-${i}`}
								/>
							);
						})}
				</>
			)}
		</div>
	);
}

export default MyClasses;
