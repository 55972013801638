import { fetchMemberships } from "@src/services/UserService";
import React, { createContext, useContext, useEffect, useState } from "react";
import { useUserContext } from "./UserContext";
import MembershipLocationMissingModal from "@src/components/MembershipLocationMissingModal/MembershipLocationMissingModal";
import { useLocation } from "react-router-dom";
import { notifyError } from "@src/utils/NotificationFunctions";

interface MembershipContextI {
	getMemberships: () => void;
	memberships: Array<any>;
	missingLocations: Array<any>;
	loadingMemberships: boolean;
}

export const MembershipContext = createContext<MembershipContextI>({
	getMemberships: () => {},
	memberships: [],
	loadingMemberships: true,
	missingLocations: [],
});

export const useMembershipContext = () => {
	return useContext(MembershipContext);
};

const MembershipsContextProvider = ({ children }: { children: React.ReactNode }) => {
	const [memberships, setMemberships] = useState<Array<any>>([]);
	const [loadingMemberships, setLoadingMemberships] = useState<boolean>(true);
	const [missingLocations, setMissinLocations] = useState<Array<any>>([]);
	const { userToken } = useUserContext();
	const { pathname } = useLocation();

	const getMemberships = async () => {
		fetchMemberships(userToken)
			.then((res) => {
				if (res.status === 200) {
					const data = res.data;
					setMemberships(data);
					validateMemberships(data);
				} else {
					setLoadingMemberships(false);
				}
			})
			.catch((error) => {
				console.error(error);
				notifyError(
					"Ha ocurrido un error al intentar obtener tus membresías." +
						"\nFavor de verificar tu conexión a internet o intentarlo más tarde."
				);
			});
	};

	const validateMemberships = (membershipsTemp: Array<any>) => {
		const locationsMissing = membershipsTemp.filter(
			(e) => e.locationId === null && e.plan?.name !== "ALL ACCESS"
		);
		setMissinLocations(locationsMissing);

		setLoadingMemberships(false);
	};

	useEffect(() => {
		if ((userToken && pathname === "/") || (memberships.length === 0 && loadingMemberships)) {
			getMemberships();
		}
	}, [userToken, pathname]);

	return (
		<MembershipContext.Provider value={{ memberships, getMemberships, loadingMemberships, missingLocations }}>
			{missingLocations.length ? (
				<MembershipLocationMissingModal show={missingLocations.length > 0} onHide={() => {}} />
			) : (
				<>{children}</>
			)}
		</MembershipContext.Provider>
	);
};

export default MembershipsContextProvider;
