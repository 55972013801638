import useUser from "@src/components/Hooks/useUser";
import { getActualUserInfo, loginAuth } from "@src/services/AuthService";
import { getScheduledClassesUser, getUserWaitingLists } from "@src/services/UserService";
import { createContext, useContext, useEffect, useState } from "react";

export const UserContext = createContext<any>(null);

export function useUserContext() {
	return useContext(UserContext);
}
// Create the AuthContextProvider component
export const UserContextProvider = ({ children }: any) => {
	const [user, setUser] = useState<any>(null);
	const [userToken, setUserToken] = useState<string>("");
	const [loading, setLoading] = useState<boolean>(true);
	const [scheduledClasses, setScheduleClasses] = useState<Array<any>>([]);
	const [waitingListClasses, setWaitingListClasses] = useState<Array<any>>([]);

	const { getUserScheduleClasses } = useUser();

	const checkSignInStatus = () => {
		const currentUser: any = localStorage.getItem("currentUser");
		if (!currentUser) {
			setUser(null);
			setLoading(false);
		} else {
			try {
				const parsedUser = JSON.parse(currentUser);
				setUserToken(parsedUser.access_token);
				setUser(parsedUser.user);
				if (parsedUser.user) {
					updateUserInfo(parsedUser.user, parsedUser.access_token);
				}
			} catch (error) {
				console.error(error);
			} finally {
				setLoading(false);
			}
		}
	};

	const login = async (email: string, password: string) => {
		try {
			const res = await loginAuth(email, password);
			if (res.status === 201) {
				localStorage.setItem("currentUser", JSON.stringify(res.data));
				checkSignInStatus();
				return;
			} else {
				return res;
			}
		} catch (error) {
			console.error(error);
			// handle login error
		}
	};

	const signOut = async () => {
		localStorage.removeItem("currentUser");
		checkSignInStatus();
	};

	const getUserSchedules = async () => {
		getUserScheduleClasses(userToken, user.id).then((res) => {
			if (res.status === 200) {
				setScheduleClasses(res.data);
			}
		});
	};

	const getUserWaitingList = () => {
		getUserWaitingLists(userToken, user.id).then((res) => {
			if (res.status === 200) {
				setWaitingListClasses(
					res.data.sort((a: any, b: any) => new Date(a.startDate).getTime() - new Date(b.startDate).getTime())
				);
			} else {
				if (res.data && res.data.message === "Unauthorized") {
					signOut();
				}
			}
		});
	};

	const updateUserInfo = (userTemp?: any, tokenTemp?: string) => {
		getActualUserInfo(userTemp ? userTemp.id : user.id, tokenTemp ? tokenTemp : userToken).then((res) => {
			let currentUser: any = localStorage.getItem("currentUser");
			currentUser = JSON.parse(currentUser);
			currentUser.user = res.data;
			localStorage.setItem("currentUser", JSON.stringify(currentUser));
			setUser(res.data);
		});
	};

	const getUserInfo = () => {
		getUserSchedules();
		getUserWaitingList();
	};

	useEffect(() => {
		const storageEventListener = (event: any) => {
			if (event.key === "currentUser" && !event.newValue) {
				setUser(null);
			}
		};

		checkSignInStatus();
		window.addEventListener("storage", storageEventListener);

		return () => {
			window.removeEventListener("storage", storageEventListener);
		};
	}, []);

	useEffect(() => {
		if (userToken && user) {
			getUserInfo();
		}
	}, [userToken, user]);

	const data = {
		user,
		login,
		loading,
		signOut,
		scheduledClasses,
		waitingListClasses,
		getUserScheduleClasses,
		getUserInfo,
		userToken,
		updateUserInfo,
	};

	if (loading) return <></>;

	return <UserContext.Provider value={data}>{children}</UserContext.Provider>;
};
