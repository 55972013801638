
import axiosInstance from "./AxiosInstance";

export async function createCharge(chargeData: any, userToken: string) {
	return axiosInstance
		.post(`/transactions/openpay/charge`, chargeData)
		.then((res) => {
			return res.data;
		})
		.catch((error) => {
			return error;
		});
}
