import { LessonT } from "@src/Interfaces/Types";
import { ReactComponent as PilatesIcon } from "@assets/icons/pilates-icon.svg";
import { ReactComponent as BarreIcon } from "@assets/icons/barre-icon.svg";
import { ReactComponent as SculptIcon } from "@assets/icons/sculpt-icon.svg";
import PowerIcon from "@assets/icons/power-pilates.jpg";

export const capitalizeFirstLetter = (str: string) => {
	return str.charAt(0).toUpperCase() + str.slice(1);
};

export function returnLessonImage(lesson: LessonT) {
	switch (lesson) {
		case "PILATES REFORMER":
			return <PilatesIcon />;
			break;
		case "BARRE POINT":
			return <BarreIcon />;
			break;
		case "SCULPT ROOM":
			return <SculptIcon />;
			break;
		case "POWER PILATES":
			return <img src={PowerIcon} alt="Power Pilates class" />;
			break;

		default:
			return <PilatesIcon />;
			break;
	}
}

const keywords = ["PILATES REFORMER", "POWER PILATES", "BARRE POINT"];

export function removeKeywords(inputString: string) {
	let result = inputString;

	keywords.forEach((keyword) => {
		result = result.replace(new RegExp(keyword, "g"), "");
	});

	// Handle cases where multiple spaces are left after replacements
	result = result.replace(/\s+/g, " ").trim();

	return result;
}
