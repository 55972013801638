import { useNavigate } from "react-router-dom";
import "./CheckoutButton.scss";

function CheckoutButton() {
  const navigate = useNavigate();
  return (
    <>
      <button
        className="btn wellness"
        onClick={() => {
          navigate("/comprar")
        }}
      >
        + Comprar
      </button>
    </>
  );
}

export default CheckoutButton;
