import { useUserContext } from "@src/context/UserContext";
import CheckoutButton from "../CheckoutButton/CheckoutButton";
import MembershipCard from "../MembershipCard/MembershipCard";
import "./UserMemberships.scss";
import SpinnerCustom from "../SpinnerCustom/SpinerCustom";
import { useCallback, useEffect, useState } from "react";
import { useMembershipContext } from "@src/context/MembershipsContext";
import { fetchMembershipHistoryByUserId } from "@src/services/Memberships";
import { notifyError } from "@src/utils/NotificationFunctions";
import { Membership } from "@src/Interfaces/Memberships";
import InactiveMembershipCard from "../InactiveMembershipCard/InactiveMembershipCard";

interface UserMembershipsPropsI {
	memberships: Array<any>;
}
type MembershipsTabs = "active" | "history";

function UserMemberships({ memberships }: UserMembershipsPropsI) {
	const { loadingMemberships } = useMembershipContext();
	const { user } = useUserContext();
	const [membershipsTab, setMembershipsTab] = useState<MembershipsTabs>("active");
	const [oldMemberships, setOldMemberships] = useState<Array<Membership>>([]);
	const [loadingHistory, setLoadingHistory] = useState(false);

	const getMembershipsHistory = useCallback(async () => {
		try {
			const res = await fetchMembershipHistoryByUserId(user.id);
			setOldMemberships(res);
		} catch (error) {
			console.error(error);
			notifyError("Hubo un error al intentar obtener el historial");
		} finally {
			setLoadingHistory(false);
		}
	}, [user]);

	useEffect(() => {
		if (membershipsTab === "history") {
			setLoadingHistory(true);
			getMembershipsHistory();
		}
	}, [membershipsTab, getMembershipsHistory]);

	return (
		<div className="memberships-cards-container-component">
			<div className="row header">
				<h2>MEMBRESÍAS</h2>
				<CheckoutButton />
			</div>
			<ul className="memberships-nav">
				<li
					className={`${membershipsTab === "active" ? "active" : ""}`}
					role="button"
					onClick={() => setMembershipsTab("active")}
				>
					Activas
				</li>
				<li
					className={`${membershipsTab === "history" ? "active" : ""}`}
					role="button"
					onClick={() => setMembershipsTab("history")}
				>
					Historial
				</li>
			</ul>
			<div className="memberships-container">
				{loadingMemberships || loadingHistory ? (
					<SpinnerCustom />
				) : (
					<>
						{membershipsTab === "active"
							? memberships.map((membership, i) => {
									return <MembershipCard membership={membership} key={`membership-card-profile-${i}`} />;
							  })
							: oldMemberships.map((membership) => (
									<InactiveMembershipCard
										membership={membership}
										key={`inactive-membership-card-${membership.id}`}
									/>
							  ))}
					</>
				)}
				<div className="disclaimer-container">
					<p className="p1">¡No lo olvides!</p>
					<p>
						Todas tus <b>reservas/reposiciones</b> deben quedar agendadas dentro de tu <b>mes de vigencia.</b>
					</p>
				</div>
			</div>
		</div>
	);
}

export default UserMemberships;
