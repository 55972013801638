import toast from "react-hot-toast";

export const notifySuccess = (message: string) => {
	toast.success(message, {
		className: "toast-notification",
		style: { background: "#0d8a0d", color: "#ffffff", fontSize: "1.6rem" },
		position: "top-right",
		duration: 6000,
	});
};

export const notifyError = (message: string) => {
	toast.error(message, {
		className: "toast-notification error",
		style: { background: "#a12727", color: "#ffffff", fontSize: "1.6rem" },
		position: "top-right",
		duration: 6000,
	});
};

export const notifyWarning = (message: string) => {
	toast(message, {
		className: "toast-notification",
		icon: "⚠️",
		style: {
			background: "#ffac4a",
			color: "#ffffff",
			fontSize: "1.6rem",
		},
		position: "top-right",
		duration: 6000,
	});
};

export function procesarErrorOpenPay(codigo: number): string {
	switch (codigo) {
		case 1003:
			return "Error en la petición";
		case 2001:
			return "Tarjeta rechazada por el banco emisor";
		case 2004:
			return "La tarjeta ha expirado";
		case 2005:
			return "Tarjeta rechazada por el sistema antifraude";
		case 3001:
			return "La tarjeta fue declinada por el banco";
		case 3002:
			return "La tarjeta ha expirado";
		case 3003:
			return "La tarjeta no tiene suficientes fondos";
		case 3012:
			return "Se necesita autorización del banco para realizar el cargo";
		default:
			return "El pago no pudo ser procesado.";
	}
}
