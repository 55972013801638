import { useUserContext } from "@src/context/UserContext";
import "./Navbar.scss";
import navLogo from "@assets/logos/horizontal.png";
import avatarImage from "@assets/images/avatar-default.png";
import { ReactComponent as Bell } from "@assets/icons/bell.svg";
import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import NotificationContainer from "../NotificationContainer/NotificationContainer";
import { getNotifications } from "@src/services/UserService";

function Navbar() {
	const { user, signOut, userToken } = useUserContext();
	const [openMenu, setOpenMenu] = useState<boolean>(false);
	const [showNotification, setShowNotification] = useState<boolean>(false);
	const navigate = useNavigate();
	const [notifications, setNotifications] = useState<Array<any>>([]);

	useEffect(() => {
		getNotifications(userToken, user.id)
			.then((res) => {
				if (res.data.length > 0) {
					setShowNotification(true);
				}
				setNotifications(res.data);
			})
			.catch((error) => {
				console.error(error);
			});
	}, []);

	return (
		<nav>
			<img src={navLogo} alt="wellness" onClick={() => navigate("/")} />
			<ul>
				<li className="notification" onClick={() => setShowNotification(!showNotification)}>
					<NotificationContainer show={showNotification} notifications={notifications} />
					<Bell />
					<div className="number-container">
						<span>{notifications.length}</span>
					</div>
				</li>
				<li className="user" onClick={() => setOpenMenu(!openMenu)}>
					<span>
						{user.name} {user.lastname}
					</span>
					<img src={avatarImage} alt="avatar" />
				</li>
			</ul>
			<div className={`sub-menu-options ${openMenu ? "open" : ""}`}>
				<div
					role="button"
					onClick={() => {
						window.open(
							"https://api.whatsapp.com/send?phone=526624171867&text=¡Hola! Deseo hacer un pago en línea, ¿me indicas cómo hacerlo?"
						);
					}}
				>
					Registrar pago
				</div>
				<div role="button" onClick={() => navigate("cambiar-contraseña")}>
					Cambiar contraseña
				</div>
				<div role="button" onClick={signOut}>
					Cerrar sesión
				</div>
			</div>
		</nav>
	);
}

export default Navbar;
