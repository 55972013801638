import { useEffect, useState } from "react";
import "./LeaveWaitingListModal.scss";
import { Modal } from "react-bootstrap";
import { cancelWaitingList } from "@src/services/ClassesService";
import { notifyError, notifySuccess } from "@src/utils/NotificationFunctions";
import { useClassesContext } from "@src/context/ClassesContext";
import { useUserContext } from "@src/context/UserContext";

interface LeaveWaitingListModalPropsI {
  show: boolean;
  onHide: () => void;
  clase: any;
	getClasses: () => void;
}

function LeaveWaitingListModal(props: LeaveWaitingListModalPropsI) {
  const { show, onHide, clase, getClasses } = props;
  const { user, userToken, getUserInfo } = useUserContext();
  const [loading, setLoading] = useState<boolean>(false);

  const addUserWaitingList = async () => {
    setLoading(true);
    cancelWaitingList(
      {
        waitingListId: clase.waiting_list ? clase.waiting_list.id : clase.id,
        userId: user.id,
      },
      userToken
    ).then((res) => {
      if (res.status === 200) {
        notifySuccess("Has abandonado la lista de espera");
        getClasses();
        getUserInfo();
        props.onHide();
      } else {
        console.error(res);
        notifyError(res.response.data.message);
      }
      setLoading(false);
    });
  };

  return (
    <Modal
      show={show}
      onHide={onHide}
      aria-labelledby="contained-modal-title-vcenter"
      centered
      backdrop={"static"}
    >
      <Modal.Header closeButton>
        <Modal.Title id="contained-modal-title-vcenter">
          <h2>Aviso</h2>
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        {clase && clase.waiting_list && (
          <h3>
            Estas en la posición:{" "}
            <b>
              {clase.waiting_list.users.findIndex(
                (e: any) => e.id === user.id
              ) + 1}
            </b>{" "}
            ¿Quieres abandonar la lista de espera?
          </h3>
        )}
        {clase && !clase.waiting_list && (
          <h3>¿Quieres abandonar la lista de espera?</h3>
        )}
      </Modal.Body>
      <Modal.Footer>
        <button className="btn secondary" onClick={props.onHide}>
          No
        </button>
        <button
          className="btn success"
          onClick={addUserWaitingList}
          disabled={!clase || loading}
        >
          Sí
        </button>
      </Modal.Footer>
    </Modal>
  );
}

export default LeaveWaitingListModal;
