import { useUserContext } from "@src/context/UserContext";
import "./Header.scss";
import { ReactComponent as Email } from "@assets/icons/mail.svg";
import { ReactComponent as InstagramIcon } from "@assets/icons/instagram.svg";
import { ReactComponent as PhoneIcon } from "@assets/icons/phone.svg";
import { useLocation } from "react-router-dom";

function Header() {
  const { user } = useUserContext();
  const location = useLocation();

  return (
    <div
      className={`header-main-container  ${
        location.pathname === "/" ? " active" : ""
      }`}
    >
      <div className="cover"></div>
      <div className="profile-information">
        <div className="avatar-container">
          <img src={user.profile_image} alt="profile" />
        </div>
        <div className="user-information">
          <div>
            <h3 className="name">
              {user.name} {user.lastname}
            </h3>
          </div>
          <div className="data-container">
            <h3>
              <PhoneIcon /> Celular: <span>{user.mobile_no}</span>
            </h3>
            <h3>
              <Email /> Email: <span>{user.email}</span>
            </h3>
            {user.metadata && user.metadata.instagram && (
              <h3>
                <InstagramIcon /> Instagram:{" "}
                <span>{user.metadata.instagram}</span>
              </h3>
            )}
          </div>
        </div>
        <button className="btn wellness">Activo</button>
      </div>
    </div>
  );
}

export default Header;
