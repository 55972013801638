import { useEffect, useState } from "react";
import "./ScheduleWaitingListModal.scss";
import { Modal } from "react-bootstrap";
import { addWaitingList } from "@src/services/ClassesService";
import { useUserContext } from "@src/context/UserContext";
import { notifyError, notifySuccess } from "@src/utils/NotificationFunctions";
import { useNavigate } from "react-router-dom";

interface ScheduleWaitingListModalPropsI {
	show: boolean;
	onHide: () => void;
	clase: any;
}

function ScheduleWaitingListModal(props: ScheduleWaitingListModalPropsI) {
	const { show, onHide, clase } = props;
	const { user, userToken, getUserInfo } = useUserContext();
	const [loading, setLoading] = useState<boolean>(false);
	const navigate = useNavigate();

	const addUserWaitingList = async () => {
		setLoading(true);
		addWaitingList({ waitingListId: clase.waiting_list.id, userId: user.id }, userToken).then((res) => {
			if (res.status === 201 && res.data?.type === "waiting_list") {
				notifySuccess("Esta en lista de espera");
				getUserInfo();
				props.onHide();
				navigate("/mis-clases");
			} else if(res.status === 201 && !res.data.type) {
        notifySuccess("Se ha reservado la clase con éxito")
        navigate("/mis-clases")
      } else {
				notifyError(res.response.data.message);
			}
			setLoading(false);
		});
	};

	return (
		<Modal
			show={show}
			onHide={onHide}
			aria-labelledby="contained-modal-title-vcenter"
			centered
			backdrop={"static"}
		>
			<Modal.Header closeButton>
				<Modal.Title id="contained-modal-title-vcenter">
					<h2>Aviso</h2>
				</Modal.Title>
			</Modal.Header>
			<Modal.Body>
				<h3>¿Estas seguro que deseas anotarte en lista de espera?</h3>
			</Modal.Body>
			<Modal.Footer>
				<button className="btn secondary" onClick={props.onHide}>
					No
				</button>
				<button className="btn success" onClick={addUserWaitingList} disabled={!clase || loading}>
					Sí
				</button>
			</Modal.Footer>
		</Modal>
	);
}

export default ScheduleWaitingListModal;
