import { format } from "date-fns";
import "./UserInformationCard.scss";

function UserInformationCard(props: any) {
  return (
    <div className="user-additional-information-card">
      <div className="info-container general">
        <div className="row">
          <h3>INFORMACIÓN GENERAL</h3>
        </div>
        <div className="row">
          <p>
            <b>Miembro desde: </b>{" "}
            {format(new Date(props.user.createdAt), "dd/MM/yyyy")}
          </p>
        </div>
        {props.user.address1 && (
          <div className="row">
            <p>
              <b>Sucursal: </b>{props.user.address1}
            </p>
          </div>
        )}
      </div>
      {props.user.metadata && (
        <div className="info-container emergency">
          <div className="row">
            <h3>EMERGENCIAS</h3>
          </div>
          <div className="row">
            <p>
              <b>Contacto: </b>
              {props.user.metadata?.emergencyContact}
            </p>
          </div>
          <div className="row">
            <p>
              <b>Celular: </b>
              {props.user.metadata?.emergencyContactPhone}
            </p>
          </div>
          <div className="row">
            <p>
              <b>Tipo de sangre: </b>
              {props.user.metadata?.bloodType}
            </p>
          </div>
          <div className="row">
            <p>
              <b>Alergias: </b>
              {props.user.metadata?.allergies}
            </p>
          </div>
          <div className="row">
            <p>
              <b>Embarazo: </b>
              {props.user.metadata?.pregnancy}
            </p>
          </div>
        </div>
      )}
    </div>
  );
}

export default UserInformationCard;
