import { useUserContext } from "@src/context/UserContext";
import "./Profile.scss";
import UserInformationCard from "@src/components/UserInformationCard/UserInformationCard";
import UserMemberships from "@src/components/UserMemberships/UserMemberships";
import { useMembershipContext } from "@src/context/MembershipsContext";

function Profile() {
	const { user } = useUserContext();
	const { memberships } = useMembershipContext();
	return (
		<div className="profile-main-container">
			<UserInformationCard user={user} />
			<UserMemberships memberships={memberships} />
		</div>
	);
}

export default Profile;
