import React from "react";
import "./MembershipSelector.scss";
import { formatStringDate } from "@src/utils/DatesFormat";

type MembershipsSelectorProps = {
	handleSelect: (membershipId: string) => void;
	selectedMembershipId: string;
	memberships: Array<any>;
};

const MembershipSelector = ({
	handleSelect,
	memberships,
	selectedMembershipId,
}: MembershipsSelectorProps) => {
	if (memberships.length === 0) {
		return (
			<div className="text-center">
				<h2 className="fs-20">No cuentas con una membresía activa</h2>
			</div>
		);
	}

	return (
		<div className="membership-selector-main-container">
			{memberships.map((membership) => {
				return (
					<div
						className={`membership-option ${selectedMembershipId === membership.id ? "active" : ""}`}
						key={membership.id}
						onClick={() => handleSelect(membership.id)}
						role="button"
					>
						{membership.lesson && (
							<h3 className="fs-20">
								<b>Plan:</b> {membership.lesson.name}
							</h3>
						)}
						{!membership.lesson && (
							<h3 className="fs-20">
								<b>Plan:</b> All access
							</h3>
						)}
						{!membership.isHorarioFijo && (
							<b className="fs-16">
								{membership.classPayed} {membership.classPayed === 1 ? "Reserva suelta" : "Reservas sueltas"}
							</b>
						)}
						{membership.isHorarioFijo && membership.plan && <b className="fs-16">{membership.plan?.name}</b>}
						<p className="fs-16">
							<b>Vencimiento:</b> {formatStringDate(membership.endDate)}
						</p>
					</div>
				);
			})}
		</div>
	);
};

export default MembershipSelector;
