import "./InactiveMembershipCard.scss";
import { Membership } from "@src/Interfaces/Memberships";
import { ISOtoStandarDate } from "@src/utils/DatesFormat";
import React, { useEffect } from "react";

const InactiveMembershipCard = ({ membership }: { membership: Membership }) => {


	return (
		<div className="inactive-membership-card">
			<div className="title">
				{membership.lessonId && <h2>{membership.lessonName}</h2>}
				{membership.lessonId === null && <h2>ALL ACCESS</h2>}
				{membership.isHorarioFijo === 1 && <p>Horario fijo</p>}
				{membership.lessonId && !membership.isHorarioFijo && (
					<p>
						<b>{membership.classPayed}</b>{" "}
						{membership.classPayed === 1 ? "reserva suelta" : "reservas sueltas"}
					</p>
				)}
			</div>
			<p className="end-date">
				<b>Vencimiento: </b>
				{membership.endDate && ISOtoStandarDate(membership.endDate)}
			</p>
		</div>
	);
};

export default InactiveMembershipCard;
