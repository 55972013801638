import { ReactNode, useEffect, useState } from "react";
import "./MainLayout.scss";
import Navbar from "@src/components/Navbar/Navbar";
import { Outlet } from "react-router-dom";
import { useUserContext } from "@src/context/UserContext";
import TermsModal from "@src/components/TermsModal/TermsModal";

interface MainLayoutPropsI {}

function MainLayout(props: MainLayoutPropsI) {
  const [termsModalShow, setTermsModalShow] = useState<boolean>(false);

  const { user } = useUserContext();

  useEffect(() => {
    if (
      (user && user.metadata && !user.metadata.terms) ||
      (user && user.metadata === null)
    ) {
      setTermsModalShow(true);
    } else {
      setTermsModalShow(false);
    }
  }, [user]);

  return (
    <div className="layout-main-container">
      {user && (
        <TermsModal
          show={termsModalShow}
          onHide={() => setTermsModalShow(false)}
        />
      )}
      {user && <Navbar />}
      <Outlet />
    </div>
  );
}

export default MainLayout;
