import { MembershipLocationI } from "@src/Interfaces/Memberships";
import axiosInstance from "./AxiosInstance";
import { isAxiosError } from "axios";

const baseUrl = "membership";

export const updateMermbershipsLocations = async (memberships: MembershipLocationI[]) => {
	try {
		const response = await axiosInstance.put(`${baseUrl}/changeLocation`, { memberships });
		return response.data;
	} catch (error) {
		throw error;
	}
};

export const fetchMembershipHistoryByUserId = async (userId: string) => {
	try {
		const res = await axiosInstance.get(`${baseUrl}/membershipHistory/${userId}`);
		return res.data;
	} catch (error: unknown) {
		if (isAxiosError(error)) {
			throw new Error(`Error: ${error.response?.data.message}`);
		}
	}
};
