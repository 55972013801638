import { useFormik } from "formik";
import "./Step2.scss";
import InputMask from "react-input-mask";
import { object, string } from "yup";
import { FormikProps } from "formik";

interface FormValues {
	cardNumber: string;
	cardHolder: string;
	cardExpiration: string;
	cardCvv: string;
}

interface PropsI {
	formik: FormikProps<FormValues>;
}

function Step2({ formik }: PropsI) {
	return (
		<div className="payment-method d-flex flex-column gap-4 border border-1 rounded p-4">
			<div className="c-row d-flex flex-column gap-2">
				<label className="fs-14" htmlFor="cardNumber">
					Número de tarjeta
				</label>
				<InputMask
					mask={"9999 9999 9999 9999"}
					maskPlaceholder={""}
					className="fs-14 py-1 px-2"
					type="text"
					name="cardNumber"
					id="cardNumber"
					placeholder="4444 4444 4444 4444"
					value={formik.values.cardNumber}
					onChange={formik.handleChange}
				/>
			</div>
			<div className="c-row d-flex flex-row gap-4 flex-wrap ">
				<div className="d-flex flex-column flex-grow-1">
					<label className="fs-14" htmlFor="cardHolder">
						Nombre
					</label>
					<input
						className="fs-14 py-1 px-2 "
						type="text"
						id="cardHolder"
						name="cardHolder"
						value={formik.values.cardHolder}
						onChange={formik.handleChange}
					/>
				</div>
				<div className="d-flex flex-column">
					<label className="fs-14" htmlFor="cardExpiration">
						Vencimiento
					</label>
					<InputMask
						mask={"99/99"}
						maskPlaceholder={""}
						className="fs-14 py-1 px-2"
						type="text"
						id="cardExpiration"
						name="cardExpiration"
						placeholder="mm/yy"
						value={formik.values.cardExpiration}
						onChange={formik.handleChange}
					/>
				</div>
				<div className="d-flex flex-column">
					<label className="fs-14" htmlFor="cardCvv">
						CVV
					</label>
					<InputMask
						mask="9999"
						maskPlaceholder={""}
						className="fs-14 py-1 px-2"
						type="text"
						id="cardCvv"
						name="cardCvv"
						placeholder="123"
						value={formik.values.cardCvv}
						onChange={formik.handleChange}
					/>
				</div>
			</div>
		</div>
	);
}

export default Step2;
