export const paymentGateways: { [key: string]: { id: string; public: string } } = {
	openpay1: {
		id: process.env.REACT_APP_OPENPAY_ID || "",
		public: process.env.REACT_APP_OPENPAY_PUBLIC || "",
	},
	openpay2: {
		id: process.env.REACT_APP_OPENPAY_ID_2 || "",
		public: process.env.REACT_APP_OPENPAY_PUBLIC_2 || "",
	},
};
