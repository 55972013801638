import { useEffect, useState } from "react";
import "./Purchase.scss";
import Step1 from "@src/components/PurchaseSteps/Step1/Step1";
import Step3 from "@src/components/PurchaseSteps/Step3/Step3";
import { ReactComponent as ChevronArrow } from "@assets/icons/chevron-arrow-right.svg";
import { ReactComponent as CartIcon } from "@assets/icons/cart-icon.svg";
import { ReactComponent as PaymentIcon } from "@assets/icons/payment-icon.svg";
import { ReactComponent as ConfirmationIcon } from "@assets/icons/confirmation-icon.svg";
import { LessonI, PlanI } from "@src/Interfaces/Lesson";
import { fetchLessons } from "@src/services/Lessons";

function Purchase() {
	const [step, setStep] = useState<number>(1);
	const [lessons, setLessons] = useState<Array<PlanI>>([]);

	const getLessons = async () => {
		await fetchLessons().then((res) => {
			const temp = [...res.data];

			temp.map((plan) => {
				plan.lessons.pricing = plan.lessons.pricing.filter((e: any) => e.showFront);
				plan.pricingIndex = 0;
			});
			setLessons(temp.filter((e) => e.name !== "SCULPT ROOM").reverse());
		});
	};

	useEffect(() => {
		getLessons();
	}, []);

	return (
		<div className="purchase-page-main-container light-shadow">
			<div className="purchase-header-steps-container">
				<div className={`step-container ${step >= 1 ? "active" : ""} text-center`}>
					<CartIcon />
					<p>Selección de planes y servicios</p>
				</div>
				<ChevronArrow className={"arrow " + (step >= 2 ? "active" : "")} />
				<div className={`step-container ${step >= 2 ? "active" : ""} text-center`}>
					<PaymentIcon />
					<p>Forma de pago</p>
				</div>
				<ChevronArrow className={"arrow " + (step >= 3 ? "active" : "")} />
				<div className={`step-container ${step === 3 ? "active" : ""} text-center text-center`}>
					<ConfirmationIcon />
					<p>Confirmación de pago</p>
				</div>
			</div>
			<Step1 step={step} setStep={setStep} lessons={lessons} />
		</div>
	);
}

export default Purchase;
