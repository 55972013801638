interface CustomSpinnerPropsI {
	size: number;
	borderWidth: number;
	color?: string;
}

function CustomSpinner({ size, borderWidth, color = "text-light" }: CustomSpinnerPropsI) {
	return (
		<div
			className={`spinner-border ${color}`}
			style={{ height: `${size}rem`, width: `${size}rem`, borderWidth: borderWidth }}
			role="status"
		>
			<span className="visually-hidden">Loading...</span>
		</div>
	);
}

export default CustomSpinner;
