import "./EmptyContainer.scss";
import { ReactComponent as NotFoundIcon } from "@assets/icons/empty.svg";

interface IEmptyContainer {
	message: string;
	showIcon?: boolean;
}

function EmptyContainer({ message, showIcon = true }: IEmptyContainer) {
	return (
		<div className="empty-container-component-main-container">
			{showIcon && <NotFoundIcon />}
			<h2>{message}</h2>
		</div>
	);
}

export default EmptyContainer;
