import { useFormik } from "formik";
import { object, ref, string } from "yup";
import "./ResetPassword.scss";
import { useUserContext } from "@src/context/UserContext";
import { changePassword } from "@src/services/UserService";
import { notifyError, notifySuccess } from "@src/utils/NotificationFunctions";
import { useNavigate } from "react-router-dom";
import { useState } from "react";

const ResetPasswordSchema = object({
  oldPassword: string().required("Este campo es obligatorio"),
  password: string()
    .required("Este campo es obligatoriio")
    .min(9, "La contraseña es muy corta - debe ser mayor o igual a 9 digitos"),
  confirmPassword: string()
    .required("Este campo es obligatorio")
    .oneOf([ref("password")], "La contraseña no coincide"),
});

function ResetPassword() {
  const { userToken, user } = useUserContext();
  const navigate = useNavigate();
  const [loading, setLoading] = useState<boolean>(false);

  const formik = useFormik({
    initialValues: {
      oldPassword: "",
      password: "",
      confirmPassword: "",
    },
    validationSchema: ResetPasswordSchema,
    onSubmit: async (values, { resetForm }) => {
      setLoading(true);
      const data = {
        password: values.oldPassword,
        new_password: values.password,
      };

      changePassword(userToken, data, user.id)
        .then((res) => {
          if (res.status === 201) {
            notifySuccess("Contraseña cambiada con exito");
            navigate("/");
          } else {
            notifyError(res.data.message);
          }
        })
        .catch((error) => {
          console.error(error);
          notifyError("Ha ocurrido un error");
        })
        .finally(() => {
          setLoading(false);
        });
    },
  });

  const { errors, touched } = formik;

  return (
    <div className="reset-password-main-container">
      <form className="reset-password" onSubmit={formik.handleSubmit}>
        <div className="c-row">
          <label htmlFor="oldPassword">Contraseña anterior:</label>
          <input
            type="password"
            id="oldPassword"
            name="oldPassword"
            value={formik.values.oldPassword}
            onChange={formik.handleChange}
          />
          {touched.oldPassword && errors.oldPassword && (
            <div className="form-error">{errors.oldPassword}</div>
          )}
        </div>
        <div className="c-row">
          <label htmlFor="oldPassword">Contraseña nueva:</label>
          <input
            type="password"
            id="password"
            name="password"
            value={formik.values.password}
            onChange={formik.handleChange}
          />
          {touched.password && errors.password && (
            <div className="form-error">{errors.password}</div>
          )}
        </div>
        <div className="c-row">
          <label htmlFor="oldPassword">Confirmar contraseña:</label>
          <input
            type="password"
            id="confirmPassword"
            name="confirmPassword"
            value={formik.values.confirmPassword}
            onChange={formik.handleChange}
          />
          {touched.confirmPassword && errors.confirmPassword && (
            <div className="form-error">{errors.confirmPassword}</div>
          )}
        </div>
        <button className="btn primary" disabled={loading}>
          Cambiar
        </button>
      </form>
    </div>
  );
}

export default ResetPassword;
