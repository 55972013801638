import "./Step3.scss";
import SuccessPay from "@assets/images/success-pay.png";

function Step3({ paymentInfo }: any) {
	return (
		<div className="p-3 d-flex flex-column justify-content-center align-items-center gap-3">
			<img src={SuccessPay} alt="successful pay" />
			<h2 className="fw-bolder">¡Tu pago <b>{paymentInfo.id}</b> ha sido realizado!</h2>
			{/* <p className="fs-16 m-0">
				Enviamos un correo electronico a <b>{paymentInfo.customer.email}</b> con la confirmación de tu pedido y el recibo
			</p>
			<p className="fs-16 m-0">
				Si el correo electrónico no llega en dos minutos. por favor revisa tu carpeta de spam para ver si el correo fue enviado
				allí
			</p> */}
			<p className="fs-16 m-0">Fecha del pago: {new Date().toLocaleString("es-ES")}</p>
		</div>
	);
}

export default Step3;
