import { NavLink, useLocation } from "react-router-dom";
import "./UserNavbar.scss";
import { useRef } from "react";

function UserNavbar() {
	// const [isOpen, setIsOpen] = useState<boolean>(false);
	const userNavbarRef: any = useRef();
	const selectedNavLinkRef: any = useRef();
	const location = useLocation();

	const navLinks = [
		{ to: "/", text: "Perfil" },
		{ to: "/mis-clases", text: "Mis reservas" },
		{ to: "/clases-disponibles", text: "Clases disponibles" },
		{ to: "/escanear", text: "Escanear QR" },
	];

	// useEffect(() => {
	// 	function handleClickOutside(event: any) {
	// 		if (selectedNavLinkRef.current && selectedNavLinkRef.current.contains(event.target)) {
	// 			// User clicked on the selected NavLink
	// 			setIsOpen((prevIsOpen) => !prevIsOpen); // toggle the state
	// 		} else if (!userNavbarRef.current || !userNavbarRef.current.contains(event.target)) {
	// 			// User clicked outside of the menu
	// 			setIsOpen(false);
	// 		}
	// 	}

	// 	// Bind the event listener
	// 	document.addEventListener("mousedown", handleClickOutside);
	// 	return () => {
	// 		// Unbind the event listener on clean up
	// 		document.removeEventListener("mousedown", handleClickOutside);
	// 	};
	// }, [userNavbarRef, selectedNavLinkRef]);

	// useEffect(() => {
	// 	setIsOpen(false);
	// }, [location.pathname]);

	return (
		<div ref={userNavbarRef} className={`user-navbar-main-container open`}>
			{navLinks.map((link) => {
				return (
					<NavLink
						ref={location.pathname === link.to ? selectedNavLinkRef : null} // Assign ref to selected NavLink
						key={link.to}
						className={({ isActive }) => (isActive ? "light-shadow isActive" : "light-shadow")}
						to={link.to}
					>
						{link.text}
					</NavLink>
				);
			})}
		</div>
	);
}

export default UserNavbar;
